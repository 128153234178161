import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})

export class PagesComponent{
  @ViewChild('sidenav') public myNav: MatSidenav;
  public isOpen: boolean;

  @Output() public isArrowRightInput = new EventEmitter();

  public onChangeArrowType = () => {
    this.isArrowRightInput.emit(this.isOpen);
  }
  
  ngDoCheck(): void {
    if (this.isOpen == false) {
      this.onChangeArrowType();
    }
  }  

  close() {
    this.isOpen = false;
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
