<div class=" mat-elevation-z8">  
  <div class="mb-2 ml-2">
    <button class="mr-2" *ngIf="selection.selected && selection.selected.length > 0" mat-raised-button color="primary" (click)="select()">Выбрать</button>
    <button mat-raised-button color="primary" mat-dialog-close (click)="onCloseChange()">Отмена</button>
    <mat-paginator [length]="resultsLength" [pageSize]="15" aria-label="Выберите страницу" [pageSizeOptions]="[15, 50, 100, 300, 1000]"> </mat-paginator>
  </div>

    <table mat-table [dataSource]="dataSource" class="" matSort matSortActive="created" matSortDisableClear
           matSortDirection="desc">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="registrationNumber">
        <th mat-header-cell *matHeaderCellDef>Регистрационный номер СНТ</th>
        <td mat-cell *matCellDef="let row">{{row.registrationNumber}}</td>
      </ng-container>

      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>Номер СНТ из учетной системы</th>
        <td mat-cell *matCellDef="let row">{{row.number}}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Дата выписки СНТ</th>
        <td mat-cell *matCellDef="let row">{{row.date?.toLocaleDateString()}}</td>
      </ng-container>

      <ng-container matColumnDef="senderTin">
        <th mat-header-cell *matHeaderCellDef>ИИН/БИН поставщика/отправителя</th>
        <td mat-cell *matCellDef="let row">{{row.senderTin}}</td>
      </ng-container>

      <ng-container matColumnDef="recipientTin">
        <th mat-header-cell *matHeaderCellDef>ИИН/БИН получателя</th>
        <td mat-cell *matCellDef="let row">{{row.recipientTin}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Статус СНТ</th>
        <td mat-cell *matCellDef="let row">{{SntStatus[row.status]}}</td>
      </ng-container>

      <ng-container matColumnDef="lastUpdateDate">
        <th mat-header-cell *matHeaderCellDef>Дата обновления СНТ</th>
        <td mat-cell *matCellDef="let row">{{row.lastUpdateDate.toLocaleDateString()}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="dataSource.loading | async"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Нет совпадений по заданному фильтру.</td>
      </tr>
    </table>
  
  <app-loading *ngIf="dataSource.loading | async" [widthPx]='100'></app-loading>  
</div>
