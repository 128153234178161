<h2 mat-mat-dialog-title>
    <span *ngIf="currentEntityMode == EsfEntities.Awp">Выбор акта выполненных работ</span>
    <span *ngIf="currentEntityMode == EsfEntities.Egp">Выбор акта выполненных работ ЭГЗ</span>
</h2>


<mat-dialog-content>
    <div class="row">
        <div class="col-3">
            <app-product-select-awp-filters 
            [senderTin]="senderTin"
            [recipientTin]="recipientTin"
            (onFilter)="onFilter($event)" 
            (onClear)="onClear()"></app-product-select-awp-filters>
        </div>
        <div class="col-9">            
            <ng-container *ngIf="currentEntityMode == EsfEntities.Awp">
                <app-product-select-awp-table (onItemSelected)="onItemSelectedAwp($event)" (onClose)="onCloseChange()"></app-product-select-awp-table>
            </ng-container>
            <ng-container *ngIf="currentEntityMode == EsfEntities.Egp">
                <app-product-select-egp-table></app-product-select-egp-table>
            </ng-container>
        </div>
    </div>
</mat-dialog-content>
