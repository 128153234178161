<input
    matInput
    [value]="displayValueInput"
    (keyup)="searchTermMeasureUnit$.next($any($event.target).value)"
    [disabled]="disabled"
    [matAutocompleteDisabled]="disabled"
    [matAutocomplete]="autoMeasureUnit"
    (input)="onSearchChange($any($event.target).value)"
    >
<mat-autocomplete  (optionSelected)="onSelected($event.option.value)" #autoMeasureUnit="matAutocomplete" [displayWith]="displayFnMeasureUnit" >
    <mat-optgroup  *ngFor="let measureUnit of filteredMeasureUnits$ | async" [label]="measureUnit.name">
        <mat-option *ngFor="let mu of measureUnit.group" [value]="mu.id">
            {{mu.name}}
        </mat-option>
    </mat-optgroup>
</mat-autocomplete>