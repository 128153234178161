<div class=" mat-elevation-z8">
  <div class="mb-2 ml-2">
    <button class="mr-2" *ngIf="selection.selected && this.selection.selected.length == 1" mat-raised-button color="primary" (click)="select()">Выбрать</button>
    <button mat-raised-button color="primary" mat-dialog-close (click)="onCloseChange()">Отмена</button>
    <mat-paginator [length]="resultsLength" [pageSize]="15" aria-label="Выберите страницу" [pageSizeOptions]="[15, 50, 100, 300, 1000]"> </mat-paginator>
  </div>  
  <table mat-table [dataSource]="dataSource" class="" matSort matSortActive="created" matSortDisableClear
         matSortDirection="desc">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="registrationNumber">
      <th mat-header-cell *matHeaderCellDef>Регистрационный номер АВР</th>
      <td mat-cell *matCellDef="let row">{{row.registrationNumber}}</td>
    </ng-container>

    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>Номер АВР из учетной системы</th>
      <td mat-cell *matCellDef="let row">{{row.number}}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Дата выписки АВР</th>
      <td mat-cell *matCellDef="let row">{{row.awpDate?.toLocaleDateString()}}</td>
    </ng-container>

    <ng-container matColumnDef="confirmationDate">
      <th mat-header-cell *matHeaderCellDef>Дата подписания (принятия) работ (услуг)</th>
      <td mat-cell *matCellDef="let row">{{row.awpSignDate?.toLocaleDateString()}}</td>
    </ng-container>

    <ng-container matColumnDef="senderTin">
      <th mat-header-cell *matHeaderCellDef>ИИН/БИН отправителя</th>
      <td mat-cell *matCellDef="let row">{{row.senderTin}}</td>
    </ng-container>

    <ng-container matColumnDef="recipientTin">
      <th mat-header-cell *matHeaderCellDef>ИИН/БИН получателя</th>
      <td mat-cell *matCellDef="let row">{{row.recipientTin}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Статус АВР</th>
      <td mat-cell *matCellDef="let row">{{getAwpStatusName(row.status)}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="dataSource.loading | async"></tr>    
  </table>

  <app-loading *ngIf="dataSource.loading | async" [widthPx]='100'></app-loading>
</div>
