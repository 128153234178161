<div class=" mat-elevation-z8">
    <div>
        <div class="mb-2">
            <button class="mr-2" *ngIf="selection.selected && selection.selected.length > 0" mat-raised-button
                color="primary" (click)="select()">Выбрать</button>
        </div>

        <table mat-table [dataSource]="data" class="" matSort matSortActive="created" matSortDisableClear
            matSortDirection="desc">
            <ng-container matColumnDef="registrationNumber">
                <th mat-header-cell *matHeaderCellDef>Регистрационный номер акта</th>
                <td mat-cell *matCellDef="let row">{{row.registrationNumber}}</td>
            </ng-container>

            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef>Номер акта из ЭГЗ</th>
                <td mat-cell *matCellDef="let row">{{row.number}}</td>
            </ng-container>

            <ng-container matColumnDef="approvedDate">
                <th mat-header-cell *matHeaderCellDef>Дата утверждения акта</th>
                <td mat-cell *matCellDef="let row">{{row.approvedDate | date}}</td>
            </ng-container>

            <ng-container matColumnDef="senderTin">
                <th mat-header-cell *matHeaderCellDef>Исполнитель/Поставщик</th>
                <td mat-cell *matCellDef="let row">{{row.senderTin}}</td>
            </ng-container>

            <ng-container matColumnDef="customerTin">
                <th mat-header-cell *matHeaderCellDef>Заказчик/Получатель</th>
                <td mat-cell *matCellDef="let row">{{row.customerTin}}</td>
            </ng-container>

            <ng-container matColumnDef="truName">
                <th mat-header-cell *matHeaderCellDef>Наименование ТРУ</th>
                <td mat-cell *matCellDef="let row">{{row.truName}}</td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Статус АВР в ЭСФ</th>
                <td mat-cell *matCellDef="let row">{{row.status}}</td>
            </ng-container>

            <ng-container matColumnDef="egpStatus">
                <th mat-header-cell *matHeaderCellDef>Статус АВР в ЭГЗ</th>
                <td mat-cell *matCellDef="let row">{{row.egpStatus}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <mat-paginator [length]="resultsLength" [pageSize]="30" aria-label="Выберите страницу">
    </mat-paginator>
</div>