<input
    matInput
    (keyup)="searchTerm$.next($any($event.target).value)"
    [value]="value"
    [disabled]="disabled"
    [matAutocompleteDisabled]="disabled"
    [matAutocomplete]="autoCurrency"
    (input)="onSearchChange($any($event.target).value)"
    >
<mat-autocomplete #autoCurrency="matAutocomplete" (optionSelected)="onSelected($event.option.value)"  [displayWith]="displayFn" >
    <mat-optgroup *ngFor="let item of filtered$ | async" [label]="item.name">
        <mat-option *ngFor="let g of item.group" (click)="onOptionClicked(g)" [value]="g.code">
            {{g.code}}
        </mat-option>
    </mat-optgroup>
</mat-autocomplete>