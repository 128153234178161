export enum SntStatus {
    DRAFT = "Черновик",
    NOT_VIEWED = "Не просмотрен",
    DELIVERED = "Доставлен",
    CREATED = "CREATED",
    IMPORTED = "Импортирован",
    FAILED = "Ошибочный",
    CONFIRMED = "Подтвержден",
    DECLINED = "Отклонен",
    CONFIRMED_BY_OGD = "Подтверждено инспектором ОГД",
    DECLINED_BY_OGD = "Отклонено инспектором ОГД",
    CANCELED = "Аннулирован",
    REVOKED = "Отозван"
}
