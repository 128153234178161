<router-outlet *ngIf="isLoggedIn"></router-outlet>

<div class="container h-100" *ngIf="!isLoggedIn">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-12">
      <app-loading [textLoading]="true" [widthPx]='100' [customText]="'Авторизация...'"></app-loading>
    </div>
  </div>
</div>

