import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiException, ProblemDetails, ValidationProblemDetails } from '../api/GCPClient';
import { SnackbarHtmlComponent } from '../pages/common/snackbar-html/snackbar-html/snackbar-html.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  info(message: string): void {
    this.snackBar.openFromComponent(SnackbarHtmlComponent, {
      data: {
        html: message,
        type: 'Внимание'
      },
      panelClass: ['info-snackbar'],
    }
    );
  }

  warning(message: string): void {
    this.snackBar.openFromComponent(SnackbarHtmlComponent, {
      data: {
        html: message,
        type: 'Внимание'
      },
      panelClass: ['warning-snackbar'],
    }
    );
  }

  notify(message: string): void {
    this.snackBar.openFromComponent(SnackbarHtmlComponent, {
      data: {
        html: message,
        type: 'Уведомление'
      },
      panelClass: ['notify-snackbar'],
    }
    );
  }

  success(succesMessage: string): void {
    this.snackBar.openFromComponent(SnackbarHtmlComponent, {
      data: {
        html: succesMessage,
        type: 'Успешно'
      },
      panelClass: ['success-snackbar'],
    }
    );
  }

  error(error: string | ApiException | ValidationProblemDetails | ProblemDetails): void {
    let errorMessage: string = this.detectAndReturnErrorMessage(error);

    this.snackBar.openFromComponent(SnackbarHtmlComponent, {
      data: {
        html: errorMessage,
        type: 'Ошибка'
      },
      panelClass: ['error-snackbar'],
      duration: undefined
    }
    );
  }

  private detectAndReturnErrorMessage(error: any) {
    if (typeof error === 'string') {
      return error;
    }

    else if (ApiException.isApiException(error)) {
      return error.message;
    }

    else if (error instanceof ProblemDetails && !('errors' in error)) {
      return error.title
    }

    else if (error instanceof ValidationProblemDetails && ('errors' in error)) {
      let errorMessage = ''
      errorMessage = error.title;
      const validationProblems = error as ValidationProblemDetails;
      if (Object.keys(validationProblems.errors).length > 0) {
        errorMessage = '<ul>';
        for (const [_, value] of Object.entries(validationProblems.errors)) {
          errorMessage += '<li>' + value + '</li>';
        }
        errorMessage += '</ul>'
      }
      return errorMessage
    }

    //error && (error.length > 0) && (error[0] instanceof FormErrorDetails)
    else if (error instanceof Map) {
      const formErrorDetails = error as Map<string, string[]>
      let errorMessage = '';
      formErrorDetails.forEach((value, key) => {
        errorMessage += '<span><strong>' + key + '</strong></span>';
        errorMessage += '<ul>';
        value.forEach(err => {
          errorMessage += '<li>' + err + '</li>';
        })
        errorMessage += '</ul>'
      })
      return errorMessage
    }

    else {
      return 'An unexpected server error occurred';
    }
  }
}
