<mat-nav-list>
    <a [routerLink]="['/modules']">
      <img src="assets/images/logo.png" alt="TCO" width="60" />
      <span class="logo nav-caption">TCO GCP</span>
    </a>
  <a *ngIf="!isOpened" mat-list-item (click)="onToggleSidenav()">
    <mat-icon>chevron_right</mat-icon>
  </a>
  <a *ngIf="isOpened" mat-list-item (click)="onSidenavClose()">
    <mat-icon>chevron_left</mat-icon>
  </a>

  <ng-container *ngFor="let menuItem of menuItems">
    <a *ngIf="hasAccess(menuItem.roles)" mat-list-item  [routerLink]="menuItem.path" (click)="onSidenavClose()">
      <mat-icon>{{menuItem.icon}}</mat-icon> <span class="nav-caption">{{ menuItem.title }}</span>
    </a>
  </ng-container>
  <ng-container>
    <a [href]="linkToTeamChannel" target="_blank" mat-list-item (click)="onSidenavClose()">
      <mat-icon>local_library</mat-icon> <span class="nav-caption">Руководства пользователя</span>
    </a>
  </ng-container>
  <div class="copyright">&copy; 2020 Tengizchevroil </div>
</mat-nav-list>
