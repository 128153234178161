<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{ label }}</mat-label>
  <div class="input-group input-container">
    <input
      [type]="fieldTextType ? 'text' : 'password'"
      formControlName="password"
      matInput
      [value]="value"
      (input)="onInputChange($event)"
    />
    <div class="input-group-append">
      <span class="input-group-text">
        <i
          class="fa"
          [ngClass]="{
            'fa-eye-slash': !fieldTextType,
            'fa-eye': fieldTextType
          }"
          (click)="toggleFieldTextType()"
        ></i>
      </span>
    </div>
  </div>
</mat-form-field>
