<mat-sidenav-container>
  <mat-sidenav class="sidenavSnt" role="navigation" [opened]="isOpen">
    <app-navigation [isArrowRightInput]="isOpen" (sidenavClose)="close()" (sidenavToggle)="toggle()"></app-navigation>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header></app-header>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>