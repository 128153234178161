<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3>{{data.type == null ? 'Надо указать data.type' : data.type}} </h3>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [innerHtml] ="data.html"></div>
    <button mat-raised-button (click)="snackBar.dismiss()" id="closeButton">Закрыть</button>
  </mat-expansion-panel>
</mat-accordion>
