import { Country } from "./lists/Countries";

export const COMPANY = {
    name: 'Товарищество с ограниченной ответственностью "Тенгизшевройл"',
    tin: '930440000929',
    address: 'Казахстан, Атырауская обл., г. Атырау, ул. КАНЫШ САТБАЕВ, д. 3',
    certificateSeries: '15001',
    certificateNum: '0010535',
}
export const BankDetails = {
    kbe: '17',
    iik: 'KZ2883201T0300106005',
    bik: 'CITIKZKA',
    bank: 'АО "Ситибанк Казахстан"'
}
export const KzCountry = 'KZ';
export const KztCurrencyCode = 'KZT';
export const USD = 'USD';
export const NOVAT = 'NOVAT';
export const KZVAT12 = 'KZVAT12%';
export const KZVAT0 = 'KZVAT0%';
export const DefaultTCOWarehouseId: number = 2;
export const CommonDataServiceReplayBufferSize = 1;
export const TopParticipantSearchByName = 20;
export const LinkToTeamsChange = "https://teams.microsoft.com/_#/files/General?threadId=19%3Ahjpo4QFKZDrGElsSZ-PsQrYwjXBDMiRy5jATMUxkl6w1%40thread.tacv2&ctx=channel&context=User%2520Guides&rootfolder=%252Fsites%252FTCOFin.PortalSNTVWEI%252FShared%2520Documents%252FGeneral%252FUser%2520Guides"
export const KZPublicOfficeBik = 'KKMFKZ2A';
export const NoConnectionToEsf = 'Прервано соединение с ИС ЭСФ.' +"<br>"+'Попробуйте позже';
export const AccountNumberInputsForGA = ['44401.158016.400', '13568.742010', '44401.599010', '44401.581011'];
