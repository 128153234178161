<div [formGroup]="form">
    <mat-form-field appearance="outline" (click)="dateFromPicker.open()">
        <mat-label>«с»:</mat-label>

        <input matInput placeholder="дд.мм.гггг" [matDatepicker]="dateFromPicker" formControlName="dateFrom">
        <mat-datepicker #dateFromPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" (click)="dateToPicker.open()">
        <mat-label>«по»:</mat-label>

        <input matInput placeholder="дд.мм.гггг" [matDatepicker]="dateToPicker" formControlName="dateTo">
        <mat-datepicker #dateToPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="form-field" appearance="outline">
        <mat-label>Регистрационный номер:</mat-label>
        <input matInput type="text" placeholder="Регистрационный номер" formControlName="registrationNumber" />
    </mat-form-field>

    
    <mat-form-field class="form-field" appearance="outline">
        <mat-label>БИН Отправителя:</mat-label>
        <input matInput type="text" placeholder="БИН Отправителя" formControlName="senderTin" />
    </mat-form-field>

    
    <mat-form-field class="form-field" appearance="outline">
        <mat-label>БИН Получателя:</mat-label>
        <input matInput type="text" placeholder="БИН Получателя" formControlName="recipientTin" />
    </mat-form-field>
</div>

<div>
    <button mat-button (click)="clear()">Очистить</button>
    <button mat-button (click)="search()">Поиск</button>
</div>