<h2 mat-mat-dialog-title>Выбор СНТ</h2>
<mat-dialog-content>
    <div class="row">
        <div class="col-3">
            <app-product-select-snt-filters (onFilter)="onFilter($event)" (onClear)="onClear()"></app-product-select-snt-filters>
        </div>
        <div class="col-9">            
            <app-product-select-snt-table (onItemSelected)="onItemSelected($event)" (onClose)="onCloseChange()"></app-product-select-snt-table>
        </div>
    </div>
</mat-dialog-content>
