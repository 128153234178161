export enum NotificationStatus {
  Successful,
  Failed,
  Queued,
}

export enum NotificationDocumentType {
  SNT,
  Invoice,
  Form,
}
