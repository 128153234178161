<div class="notification-bell-container">
  <a mat-icon-button (click)="onBellIconClick()" [matMenuTriggerFor]="menu">
    <mat-icon class="icon" [matBadge]="notificationCount > 0 ? notificationCount : null" matBadgeColor="accent"
      matBadgeSize="small">notifications</mat-icon>
  </a>
  <mat-menu #menu="matMenu" class="notification-box" matMenuTriggerBackdropClass="" (closed)="markAllAsRead()">
    <div class="notification-box-header">
      <span>Уведомления</span>
      <a mat-button class="notificatioin-box-link"><a [routerLink]="['/allnotifications']">Посмотреть все</a></a>
    </div>

    <div *ngIf="notifications.length === 0" class="no-notifications">
      <mat-icon>drafts</mat-icon>
      <p>Нет новых уведомлении</p>
    </div>
    <div *ngIf="notifications.length > 0" (click)="$event.stopPropagation()">
      <div *ngFor="let notification of notifications; let isLast = last">
        <div class="notification-item">
          <div class="notification-left">
            <mat-icon [ngStyle]="{ color: getIconColorByStatus(notification.status) }">{{
              getIconByStatus(notification.status) }}</mat-icon>
            <div class="notification-info">
              <div class="notification-title">
                {{getNotificationMessage(notification)}}
              </div>
              <div class="notification-status">{{ getStatusText(notification.status) }}</div>
              <div class="notification-message">
                <span *ngIf="isFieldDisplayed(notification, 'cancelReason')" [innerHTML]="notification.cancelReason"></span>
                <span *ngIf="isFieldDisplayed(notification, 'registrationNumber')">{{ notification.registrationNumber }}</span>
              </div>
            </div>
          </div>
          <div class="notification-right">
            <div class="notification-date">
              {{ notification.responseDateTime | date : "dd-MM-yyyy h:mm:ss"}}
            </div>
          </div>
        </div>
        <mat-divider *ngIf="!isLast"></mat-divider>
      </div>
    </div>
  </mat-menu>
</div>