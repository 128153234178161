<div [formGroup]="form">
    <mat-form-field class="form-field" appearance="outline">
        <mat-label>Номер СНТ из учетной системы:</mat-label>
        <input matInput type="text" placeholder="Номер СНТ из учетной системы" formControlName="number" />
    </mat-form-field>

    <mat-form-field appearance="outline" (click)="dateFromPicker.open()">
        <mat-label>Дата выписки СНТ «с» :</mat-label>
        <input matInput placeholder="дд.мм.гггг" [matDatepicker]="dateFromPicker" formControlName="dateFrom">
        <mat-datepicker #dateFromPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" (click)="dateToPicker.open()">
        <mat-label>Дата выписки СНТ «по» :</mat-label>
        <input matInput placeholder="дд.мм.гггг" [matDatepicker]="dateToPicker" formControlName="dateTo">
        <mat-datepicker #dateToPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="form-field" appearance="outline">
        <mat-label>Регистрационный номер СНТ:</mat-label>
        <input matInput type="text" placeholder="Регистрационный номер СНТ" formControlName="registrationNumber" />
    </mat-form-field>
</div>

<div>
    <button mat-button (click)="clear()">Очистить</button>
    <button mat-button (click)="search()">Поиск</button>
</div>