<div class="d-flex flex-column align-items-center justify-content-center">
  <div class="row">
    <div class="col-auto">
      <div [ngStyle]="{width: widthPx+'px', height: widthPx+'px'}" class="spinner-border text-info">
      </div>
    </div>
  </div>
  <div class="row mt-1">
    <span  [style.font-size.px]="widthPx*0.3" *ngIf='textLoading'>{{customText ? customText : 'Загрузка...'}}</span>
  </div>
</div>
